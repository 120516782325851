import tw from 'twin.macro'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

interface Prop {
	sources: {
		src: string
		srcSet: string
		srcSetWebp: string
		srcWebp: string
		media: string
		base64: string
	}[]
}

interface ItemText {
	padding?: string
	fontStyle?: string
	background?: string
	fontSize?: string
}

interface Item {
	bg?: {
		fluid: {
			src: string
			srcWebp: string
		}
	}
	bgPosition?: string
	padding?: string
}
type BookButton = ItemText

export const WhichModelSection = styled.section<Prop>`
	height: 100%;
	${tw`w-full bg-cover bg-no-repeat`}
	background-color: #c5cbc0;
	background-position: 100% 70%;
	background-image: url(${({ sources }) => sources[0].base64});
	min-height: 750px;
	@media (min-width: 1280px) and (max-width: 1399px) {
		min-height: 650px;
	}
	@media (min-width: 1024px) and (max-width: 1279px) {
		min-height: 550px;
	}
	@media (min-width: 768px) and (max-width: 1023px) {
		min-height: 550px;
	}
	@media (max-width: 767px) {
		min-height: 450px;
	}
`
export const WhichModelContainer = styled.div<Prop>`
	${tw`flex flex-col w-full h-full py-6 md:py-12 lg:pt-6 xl:pt-12 relative`}
	${tw`bg-cover bg-no-repeat bg-center`}
	min-height: 750px;
	@media (min-width: 1280px) and (max-width: 1399px) {
		min-height: 650px;
	}
	@media (min-width: 1024px) and (max-width: 1279px) {
		min-height: 550px;
	}
	@media (min-width: 768px) and (max-width: 1023px) {
		min-height: 550px;
	}
	@media (max-width: 767px) {
		min-height: 450px;
	}
	@media (min-width: 1700px) {
		background-image: url(${({ sources }) => sources[0].srcWebp});
		background-image: url(${({ sources }) => sources[0].src});
	}
	@media (min-width: 1400px) and (max-width: 1699px) {
		background-image: url(${({ sources }) => sources[1].srcWebp});
		background-image: url(${({ sources }) => sources[1].src});
	}
	@media (min-width: 1280px) and (max-width: 1399px) {
		background-image: url(${({ sources }) => sources[2].srcWebp});
		background-image: url(${({ sources }) => sources[2].src});
	}
	@media (min-width: 1024px) and (max-width: 1279px) {
		${tw`bg-center`}
		background-image: url(${({ sources }) => sources[3].srcWebp});
		background-image: url(${({ sources }) => sources[3].src});
	}
	@media (min-width: 320px) and (max-width: 1023px) {
		${tw`bg-bottom`}
		background-image: url('//images.ctfassets.net/ftcnv5up9dxv/6nYb4hf38SSDVnRaUNRbI6/96fb0d9e5ba61a0fb03f24be0902ebda/which-model-mobile.jpg?w=767&h=1000&q=80&fit=fill&f=bottom');
	}
`
export const WhichModelP = tw.p`text-center text-white font-display leading-normal text-base lg:text-lg`
export const WhichModelTextContainer = tw.div`w-95 lg:w-full mx-auto`
export const WhichModelHook = tw.h2`font-display text-4xl xl:text-5xl text-center font-bold mb-4`
export const WhichModelSubtext = styled.p`
	${tw`font-display text-2xl lg:text-3xl xl:text-4xl text-white text-center font-bold`}
	text-shadow: 10px 6px 8px rgba(0,0,0,.75);
`
export const WhichModelDescription = tw.div`w-11/12 md:w-10/12 lg:w-7/12 xl:w-6/12 xxl:w-5/12 mx-auto mt-8 xl:mt-16 flex flex-col items-center`
export const WhichModelButton = styled(Link)`
	${tw`bg-yellow-500 hover:bg-yellow-400 uppercase py-3 px-4 w-40 font-display text-center font-bold rounded-md mt-10`}
`
export const Italic = tw.i`font-black`

export const SupportContactContainer = tw.div`flex flex-col md:flex-row w-full`
export const SupportContainer = tw.div`flex flex-col w-full md:w-6/12 py-8 lg:py-16 items-center bg-white`
export const SupportContainerHolder = tw.div`w-11/12 lg:w-11/12 xl:w-4/5 mx-auto`
export const ContactContainer = styled.div`
	${tw`flex flex-col w-full md:w-6/12 p-8 lg:p-16 items-center`}

	> form > div {
		${tw`m-0`}
	}
	background: #66b8d0; /* Old browsers */
	background: linear-gradient(to left, #1b7db3 0%, #2e8fb1 25%, #58c8df 70%, #83d2e4 100%);
`
export const SupportTitle = tw.h3`font-display text-6xl text-yellow-500 font-bold mb-2 text-center`
export const SupportSubTitle = tw.p`font-sans text-lg mb-12 xl:w-11/12 xxl:w-full text-center mx-auto`
export const ItemsContainer = tw.div`flex flex-col items-center w-11/12 lg:w-11/12 xl:w-11/12 xxl:w-4/5 mx-auto xxxl:px-8 h-full justify-center`
export const SupportItem = styled(Link)`
	${tw`flex flex-row border-0 border-black px-2 md:px-4 py-6 w-full bg-gray-100 hover:bg-yellow-50 mb-4 rounded`}
`
export const SvgContainer = tw.div`flex items-center justify-center w-3/12 text-yellow-500`
export const ItemInfo = tw.div`flex flex-col w-9/12`
export const ItemTitle = tw.p`font-display text-xl font-bold mb-2`
export const ItemSub = tw.p`font-display text-xs lg:text-sm font-normal`
export const ContactTitle = tw.h3`font-display text-5xl md:text-6xl text-white font-bold mb-2`
export const ContactDescription = tw.p`font-sans text-lg mb-12 text-gray-800 text-center w-11/12`

export const Section = tw.section`flex flex-col md:flex-row w-full bg-gray-100 py-16 md:py-24`
export const Container = tw.div`flex flex-col md:flex-row xl:w-4/5 xxl:w-3/4 xxxl:w-3/5 m-auto`
export const Item = styled.div<Item>`
	${tw`relative w-full bg-no-repeat bg-cover h-full`}
	min-height: 300px;
	background-color: #bfbfbf;
	background-image: ${({ bg }) => (bg ? `url(${bg.fluid.srcWebp})` : ``)};
	background-image: ${({ bg }) => (bg ? `url(${bg.fluid.src})` : ``)};
	background-position: ${({ bgPosition }) => (bgPosition ? bgPosition : `center`)};
	padding: ${({ padding }) => (padding ? padding : '4rem')};

	@media (max-width: 1023px) {
		padding: ${({ padding }) => (padding ? padding : '3rem')};
		> form > div > div {
			${tw`w-full px-0`}
		}

		> form > div:last-of-type {
			${tw`px-0`}
		}
	}
	> form > div {
		${tw`m-0`}
	}
`
export const FormItem = styled(Item)`
	${tw`w-10/12 md:w-1/3 h-auto m-auto md:mx-1 lg:mx-2 my-2 md:my-0`}
`
export const ItemBackgroundGradient = styled.div`
	${tw`absolute top-0 left-0 w-full h-full`}
	background: linear-gradient(to bottom, #000 0%,#353e47cc 20%,#353e4700 100%);
`
export const ItemDescription = tw.p`text-3xl lg:text-4xl text-white font-display mt-6`
export const MultipleItemContainer = styled.div`
	${tw`flex flex-col w-full md:w-1/3 mx-0 md:mx-1 lg:mx-2`}
`
export const MultipleItem = styled.div<{ marginBottom: string; bg: { fluid: { src: string } } }>`
	${tw`h-full bg-center bg-no-repeat bg-cover flex flex-col`}
	margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom} !important` : '')};
	background-image: ${({ bg }) => (bg ? `url(${bg.fluid.src})` : ``)};
	background-color: #bfbfbf;

	@media (max-width: 767px) {
		min-height: 300px;
	}
`
export const MultipleItemLink = styled(Link)`
	${tw`m-auto w-10/12 md:w-full h-full`}

	&:not(:last-of-type) {
		${tw`lg:mb-4`}
	}
`
export const MultipleItemSplit = styled.div<{ bg: { fluid: { src: string } } }>`
	${tw`h-full bg-center bg-no-repeat bg-cover m-auto w-full flex flex-col`}
	background-image: ${({ bg }) => (bg ? `url(${bg.fluid.src})` : ``)};
	background-color: #bfbfbf;

	@media (max-width: 767px) {
		min-height: 270px;
	}
`

export const FirstItemText = styled.h3`
	${tw`font-display text-4xl lg:text-5xl`}
	color: ${({ color }) => (color === '#fff' ? color : color)};
`

export const ItemText = styled.p<ItemText>`
	${tw`font-display text-white font-bold flex flex-row items-center justify-center text-lg lg:text-xl`}
	padding : ${({ padding }) => (padding ? padding : '')};
	color: ${({ color }) => (color === '#fff' ? color : color)};
	font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : '')};
	background: ${({ background }) => (background ? background : '')};
`
export const LastItemText = styled(ItemText)`
	${tw`py-8 px-1 lg:px-8 xxl:px-12 font-bold`}
`
export const Logo = tw.img`mr-4`
export const FormTitle = tw.h3`w-full font-display text-4xl text-white font-bold mt-10 mb-4 text-center relative`
export const FormDescription = tw.p`text-sm font-display text-white mb-8 text-center relative w-full lg:w-11/12 xxxl:w-10/12 mx-auto`
export const PostLink = styled(Link)`
	${tw`w-10/12 md:w-1/3 m-auto md:mx-1 lg:mx-2 md:my-0 my-2 md:my-0`}
`
export const BookButton = styled.button<BookButton>`
	padding: ${({ padding }) => (padding ? padding : '')};
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '')};
	color: ${({ color }) => (color === '#fff' ? color : color)};
	font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : '')};
	background: ${({ background }) => (background ? background : '')};
`
export const BookLink = styled(Link)`
	${tw`font-display bg-yellow-500 hover:bg-yellow-400 py-2 px-4 rounded`}
`
export const BannerContainer = styled.div<{ bg?: { fluid: { src: string } } }>`
	${tw`bg-center bg-no-repeat bg-cover flex flex-col m-auto w-10/12 md:w-full h-full mb-2`}
	background-image: ${({ bg }) => (bg ? `url(${bg.fluid.src})` : ``)};
	background-color: #bfbfbf;

	@media (max-width: 767px) {
		min-height: 300px;
	}
`
export const Bold = tw.b`font-bold`
